@charset "utf-8";

@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

body{padding:0;margin:0;font-weight:400;font-family: 'Work Sans', sans-serif;word-break:break-word;}
ul{list-style:none}
a{text-decoration:none!important;outline:none}
body a:hover,body a:focus{color:initial;text-decoration:none}
a:focus{outline:none;text-decoration:none}
textarea:focus{outline:none}
select:focus{outline:none;}
input{outline:none!important}
button,button:focus{outline:none}
a img{border:none}
img{border:0}
p{padding:0;margin:0}
h1,h2,h3,h4,ul,p{padding:0;margin:0}
textarea{resize:none}
input::-webkit-input-placeholder,textarea::-webkit-input-placeholder{color:#fff}
input:-moz-placeholder,textarea:-moz-placeholder{color:#fff}
input:-webkit-autofill{border:1px solid #e4e4e5;-webkit-text-fill-color:rgba(49,50,51,1);-webkit-box-shadow:0 0 0px 1000px #f5f5f6 inset;transition:background-color 5000s ease-in-out 0s;}
input:-internal-autofill-selected{background-color:#fff!important;-webkit-box-shadow:inset 0 0 0px 1000px #fff;box-shadow:inset 0 0 0px 1000px #fff;}
input:-webkit-autofill:hover,input:-webkit-autofill:focus{border:1px solid #e4e4e5;-webkit-text-fill-color:rgba(49,50,51,1);-webkit-box-shadow:0 0 0px 1000px #f5f5f6 inset;transition:background-color 5000s ease-in-out 0s;}
input::-moz-placeholder{opacity:1;outline:none}
textarea::-moz-placeholder{opacity:1;outline:none}
.clr{clear:both;height:0}
ul{padding:0;list-style:none}
a{text-decoration:none;-moz-transition:all .2s;-webkit-transition:all .2s;transition:all .2s}
a:hover{text-decoration:none;-moz-transition:all .2s;-webkit-transition:all .2s;transition:all .2s}

.headertop .container { display: flex; align-items: center; align-content: center; justify-content: space-between; } .headerRight ul {display: inline-block; vertical-align: middle; list-style: none; padding: 0; margin: 0 20px 0 0; } 
.headerRight ul li { display: inline-block; vertical-align: middle; margin: 0 20px 0 0; } 
.headerRight ul li a { display: flex; width: 49px; height: 49px; background: #FFFFFF; border: 1px solid #F72585; align-items: center; align-content: center; justify-content: center; border-radius: 60px; } 
.headerRight ul li:last-child { margin: 0; }

.bluebg {background: #7B3FE4;}
.topbtns { display: inline-block; vertical-align: middle; } 
.submitcvbtn { display: inline-block; padding: 12px 20px;margin: 0 10px 0 0; border: 1px solid #7B3FE4; border-radius: 5px; font-family: 'Work Sans'; font-style: normal; font-weight: 500; font-size: 18px; line-height: 21px; text-align: center; text-transform: uppercase; color: #7B3FE4;background: transparent; }
.submitcvbtn:hover, .submitcvbtn:focus {background: #7B3FE4; color: #fff;}


.bluebtn { display: inline-block; padding:14px 24px; border-radius: 5px; font-family: 'Work Sans'; font-style: normal; font-weight: 500; font-size: 18px; line-height: 21px; text-align: center; color: #FFFFFF;text-transform: uppercase;animation: change-background 4s  infinite ease;border: none;outline: none !important; }
.bluebtn:hover, .bluebtn:focus { background: #F72585; color: #fff; 
   }

/* 11 */
.custom-btn { width: 130px; height: 40px; color: #fff; border-radius: 5px; padding: 10px 25px; font-family: 'Lato', sans-serif; font-weight: 500; background: transparent; cursor: pointer; transition: all 0.3s ease; position: relative; display: inline-block; box-shadow: inset 2px 2px 2px 0px rgb(255 255 255 / 50%), 7px 7px 20px 0px rgb(0 0 0 / 10%), 4px 4px 5px 0px rgb(0 0 0 / 10%); outline: none; }
.button { display: inline-block; position: relative; background: #7B3FE4; border-radius: 5px; will-change: transform; padding: 14px 20px 14px 20px; }
.button-mat{ border: 0px transparent; border-radius: 0.3rem; transition: 0.3s ease-in-out; transition-delay: 0.35s; overflow: hidden; font-family: 'Work Sans'; font-style: normal; font-weight: 500; font-size: 18px; line-height: 21px; text-align: center; text-transform: uppercase; color: #FFFFFF;}
.button-mat:before{content: ""; display: block; background: #F72585; position: absolute; width: 200%; height: 500%; border-radius: 100%; transition: 0.36s cubic-bezier(0.4,0,1,1); z-index: -1;}
.button-mat:hover .psuedo-text{color:white;}
.button-mat:hover{color:#fff;}

.btn--7 {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
  .btn--7:before {
    transform: translate(-110%, -110%) translateZ(0);
  }
  .btn--7:hover:before {
    transform: translate(-45%, -34%) translateZ(0);
  }

.headertop { padding: 15px 0; border-bottom: 1px solid #EEEEEE; }

.menu a.nav-link { font-family: 'Work Sans'; font-style: normal; font-weight: 500; font-size: 16px; line-height: 19px; text-align: center; text-transform: uppercase;  color: #191919; margin: 0 50px 0 0; }
.menu a.nav-link:hover, .menu a.nav-link:focus { color: #F72585;}
.menu a.nav-link.active { color: #F72585; }
nav.navbar.navbar-expand-lg { padding: 15px 0; background: none!important; }



.mainbanner {overflow: hidden; background: #160B29 url(./img/bannerbg.svg)right center no-repeat; padding: 80px 0;background-position: 80% center; } 
/* Circle Animation */
:root{--shadowShift:2px;--shadowBlur:2px;--circleColor:rgb(123,63,228);}
.wave{position:relative;}
.wave__btn{z-index:100;width:80px;height:80px;border-radius:20px;border:none;background-color:var(--circleColor);transform:rotate(45deg);transition:transform 0.2s ease-out;box-shadow:var(--shadowShift) 0 var(--shadowBlur) rgba(55 84 170 / 0.2),calc(var(--shadowShift) * -1) 0 var(--shadowBlur) rgba(255 255 255 / 1); }
.wave__btn:hover{cursor:pointer;transform:rotate(45deg) scale(1.1);}
.wave__btn::before{content:"CLICK";color:#31373f;font-weight:bold;font-size:12px;position:absolute;top:0;left:0;right:0;bottom:0;display:flex;justify-content:center;align-items:center;margin:auto;transform:rotate(-45deg);}
.wave__btn:focus{outline:none;}
.wave__btn:focus-visible{border-color:#89aedf;}
.wave__btn:active{opacity:0.7;transform:rotate(45deg) scale(1);}
/* .wave__container{position:absolute;top:0;left:0;right:0;bottom:0;} */
.wave__container {
    position: absolute;
    top: 70px;
    left: 20px;
}
.wave__circle{position:absolute;background-color:transparent;border:1px solid var(--circleColor);border-radius:50%;box-shadow:inset var(--shadowShift) var(--shadowShift) var(--shadowBlur)
rgba(55 84 170 / 0.2),var(--shadowShift) var(--shadowShift) var(--shadowBlur)
rgba(55 84 170 / 0.2),inset calc(var(--shadowShift) * -1) calc(var(--shadowShift) * -1)
var(--shadowBlur) rgba(255 255 255 / 1),calc(var(--shadowShift) * -1) calc(var(--shadowShift) * -1)
var(--shadowBlur) rgba(255 255 255 / 1);/*filter:blur(1px);*/animation-name:ripple;animation-duration:3s;animation-timing-function:cubic-bezier(0,0.2,0.8,1);animation-iteration-count:infinite;}

.devdescription { background: #fff; max-width: 130px; border-radius: 8px; padding:0 0 4px 0px; }
.devdescription img { width: 100%; border-radius: 8px 8px 0 0;}
.devdescription strong { margin: 0; padding: 2px 1px 0 6px; font-family: 'Work Sans'; font-style: normal; font-weight: 500; font-size: 16px; line-height: 18px; color: #000;}
.devdescription p { margin: 0; padding: 0px 6px 2px 6px; font-family: 'Work Sans'; font-style: normal; font-weight: 400; font-size: 14px; line-height: 18px; color: #000;}
.viewdeveloper a img { opacity: 0; } 
ul.servicelist li:hover .viewdeveloper a img { opacity:1}

.wave__container .wave__circle:nth-child(2){animation-delay:-1s;}
.wave__container .wave__circle:nth-child(3){animation-delay:-2s;}
@keyframes ripple{
0%{top:250px;left:250px;width:0;height:0;opacity:1;}
100%{top:0;left:0;width:500px;height:500px;opacity:0;}
}
.updatetwitimg { position: relative; z-index: 1; }
.bannerimg { max-width: 560px; margin-left: auto; } 
.twitimgupdate1 { position: absolute; top: 0; left: 0; width: 130px; } 
.twitimgupdate2 { position: absolute; left: 189px; top: 40px; width: 130px; } 
.twitimgupdate3 { position: absolute; right: 0; top: 0; width: 180px; }
.twitimgupdate4 { position: absolute; left: 20px; top: 240px; width: 180px; } 
.twitimgupdate5 { position: absolute; right: 0px; top: 220px; width: 180px; }


.twitimgupdate2 .devdescription { max-width: 145px;}
.twitimgupdate3 .devdescription { max-width: 120px;}
.twitimgupdate4 .devdescription { max-width: 170px;}
.twitimgupdate5 .devdescription { max-width: 152px;}






.bannercnt h1 { font-family: 'Work Sans'; font-style: normal; font-weight: 600; font-size: 58px; line-height: 68px; text-transform: capitalize; color: #FFFFFF; } 
.redtext { color: #F72585; } 
.bannercnt p {margin: 20px 0 30px; font-family: 'Work Sans'; font-style: normal; font-weight: 400; font-size: 22px; line-height: 30px; color: #FFFFFF; } 
.trusted { padding: 110px 0 0; } 
.trusted h2 { font-family: 'Work Sans'; font-style: normal; font-weight: 600; font-size: 16px; line-height: 19px; letter-spacing: 0.1em; color: rgba(255, 255, 255, 0.5); margin: 0 0 10px; } 
.trusted ul { padding: 0; } 
.trusted ul li {display: inline-block;vertical-align: middle;margin: 0 20px 0 0;} 
.bannercnt { padding: 20px 0 0; }

.ourservsec {overflow: hidden; padding: 70px 0 20px; background:url(../public/images/colorbannerimg01.png)0 0 no-repeat; background-size: cover;} 
.blueheading { text-align: center; font-family: 'Work Sans'; font-style: normal; font-weight: 600; font-size: 36px; line-height: 42px; text-transform: capitalize; color: #7B3FE4; margin: 0 0 10px; } 
.servicetitle {margin: 0 0 30px; font-family: 'Work Sans'; font-style: normal; font-weight: 400; font-size: 18px; line-height: 30px; text-align: center; color: #191919; } 
ul.servicelist { position: relative; padding: 0; display: flex; flex-wrap: wrap; } 
ul.servicelist li {display: inline-block; width: 33.33%; border: 1px solid #DDE8EE; padding: 30px; margin-right: -1px; margin-bottom: -1px; background: #fff; position: relative; transition: all ease .3s; } 

ul.servicelist li:after, 
ul.servicelist li:after {  content: ''; position: absolute; top: 0; bottom: 0; left: 0; right: 0; z-index: 1; transition: all ease .3s; }
.viewdeveloper { position: relative; z-index: 2; }

ul.servicelist li:hover:after, ul.servicelist li:focus:after { box-shadow: 0 0 11px 8px rgb(0 0 0 / 10%); }
.viewdeveloper { position: relative; z-index: 2; }


ul.servicelist li h2 { font-family: 'Work Sans'; font-style: normal; font-weight: 600; font-size: 24px; line-height: 28px; text-transform: capitalize; color: #191919; margin: 20px 0 10px; } 
ul.servicelist li p { font-family: 'Work Sans'; font-style: normal; font-weight: 400; font-size: 16px; line-height: 28px; color: #525252; }

ul.servicelist:before { background: url(./img/doteimg.svg)0 0 no-repeat; width: 220px; height: 160px; content: ''; position: absolute; left: -120px; top: -10px; }

ul.servicelist::after { background: url(./img/doteimg.svg)0 0 no-repeat; width: 120px; height: 160px; content: ''; position: absolute; right: -70px; bottom: 20px; }  

.viewdeveloper a { font-family: 'Work Sans'; font-style: normal; font-weight: 500; font-size: 16px; line-height: 19px; text-transform: uppercase; color: #7B3FE4; } 
.viewdeveloper a img { display: inline-block; vertical-align: middle; margin: 0 0 0 10px; }
.viewdeveloper a:hover, .viewdeveloper a:focus { color: #7B3FE4;}


.induslogobox { display: inline-flex; background: #FFFFFF; box-shadow: 0px 0px 30px rgba(5, 72, 109, 0.12); border-radius: 10px; margin: 22px 13px 22px 0; padding: 15px; align-items: center; align-content: center; justify-content: flex-start; } 
.induslogobox p { font-family: 'Work Sans'; font-style: normal; font-weight: 500; font-size: 16px; line-height: 19px; color: #525252; margin: 0; } 
.induslogobox img { display: inline-block; margin: 0 10px 0 0; }

.projectstartsec { overflow: hidden; padding: 0 0 80px; }
.projectstartbluesec { background: #7B3FE4; padding: 70px 10px 200px; text-align: center; } 
.projectstartbluesec h2 { font-family: 'Work Sans'; font-style: normal; font-weight: 600; font-size: 36px; line-height: 42px; text-transform: capitalize; color: #fff; margin: 0 0 10px; } 
.projectstartbluesec p { font-family: 'Work Sans'; font-style: normal; font-weight: 400; font-size: 18px; line-height: 30px; /* or 167% */ text-align: center; color: #E1E9ED; } 
.projectsteps { margin-top: -150px; } 
.prostepbox { background: #FFFFFF; border-radius: 10px; filter: drop-shadow(0px 10px 30px rgba(0, 0, 0, 0.12)); text-align: center; position: relative; padding: 40px 10px 10px 10px;    transition: all ease .3s; } 
.prostepbox:hover, .prostepbox:focus { box-shadow: 0 0 25px 0 rgb(0 0 0 / 50%); transition: all ease .3s; }
.stepcount { width: 70px; height: 70px; background: #EE334E; border: 5px solid #FFFFFF; border-radius: 60px; display: flex; margin: auto; align-items: center; align-content: center; justify-content: center; font-family: 'Work Sans'; font-style: normal; font-weight: 600; font-size: 28px; line-height: 33px; text-align: center; text-transform: capitalize; color: #fff; position: absolute; left: 0; right: 0; top: -30px; }

.prostepbox h3 { font-family: 'Work Sans'; font-style: normal; font-weight: 600; font-size: 24px; line-height: 28px; text-align: center; text-transform: capitalize; color: #191919; } 
.prostepbox p { font-family: 'Work Sans'; font-style: normal; font-weight: 400; font-size: 16px; line-height: 28px; text-align: center; color: #525252; }

.prostepbox > img { max-height: 160px; object-fit: contain; }
.prostepbox h3 {margin: 14px 0 10px;}


.hiredevelopersec {margin-top: -300px;padding:280px 0 70px; background: url(../public/images/hiredevbannerimg01.png)0 0 no-repeat; background-size: cover; } 
.hiredevtitle { font-family: 'Work Sans'; font-style: normal; font-weight: 400; font-size: 18px; line-height: 30px; text-align: center; color: #142026; } 
.hiredev { display: flex; align-items: center; align-content: center; justify-content: space-between; margin: 60px 0 0; } .notsatis { width: 20%; text-align: right; } 
.notsatis h2 { font-family: 'Work Sans'; font-style: normal; font-weight: 600; font-size: 22px; line-height: 26px; text-transform: capitalize; /* h1 */ color: #191919; margin: 5px 0 5px; text-align: right; } 
.notsatis p { font-family: 'Work Sans'; font-style: normal; font-weight: 400; font-size: 16px; line-height: 26px; color: #525252; text-align: right; } 
.hiredevimg { width: 23%;text-align: center; } 
.hiredevprocess { width: 53%; } 
.hiredevlist {display: flex;align-items: center;align-content: center;justify-content: flex-start;margin: 0 0 50px;} 
.devproceeicon { width: 30%; text-align: center; } 
.hiredevdesc {width: 70%; padding-left: 20px; } 
.hiredevdesc h2 { font-family: 'Work Sans'; font-style: normal; font-weight: 600; font-size: 22px; line-height: 26px; text-transform: capitalize; color: #191919; } 
.hiredevdesc p { font-family: 'Work Sans'; font-style: normal; font-weight: 400; font-size: 16px; line-height: 26px; color: #525252; } 
.hiredevlist:last-child { margin: 0; }
.whychoose {padding: 70px 0 0; background: linear-gradient(91deg, #E4E4E3 0%, #e8e8e8 46.73%, #f1f1f1 100%); } 
.whychoose .blueheading { text-align: left; } 
.whychoose p { font-family: 'Work Sans'; font-style: normal; font-weight: 400; font-size: 18px; line-height: 30px; color: #525252; margin: 0 0 15px; } 
.whychoose ul { padding: 0; } 
.whychoose ul li { position: relative; padding: 5px 0 14px 44px; margin: 0 0 10px; font-family: 'Work Sans'; font-style: normal; font-weight: 600; font-size: 20px; line-height: 23px; text-transform: capitalize; color: #191919; background: url(./img/greencheckicon.svg) 0 0 no-repeat; }
.whychoose .col-md-6:first-child { padding-top: 60px; padding-bottom: 0; }
.recruitsec { padding: 70px 0;overflow: hidden; background: url(../public/images/recruiterbannerimg01.png)0 0 no-repeat; background-size: cover; } 
.recruitfound { background: url(../public/images/founderbg.svg)0 0 no-repeat;background-size: cover; display: flex; align-items: center; align-content: center; justify-content: space-between; padding: 20px;border-radius: 30px;}
.recruitcnt { text-align: center; } 
.recruitcnt h2 {margin: 0 0 14px; font-family: 'Work Sans'; font-style: normal; font-weight: 600; font-size: 38px; line-height: 45px;  text-align: center; text-transform: capitalize; color: #FFFFFF; } 
.recruitcnt p {margin: 0 0 35px; font-family: 'Work Sans'; font-style: normal; font-weight: 400; font-size: 18px; line-height: 30px; text-align: center; color: #FFFFFF; } 
.finddeveloper a { display: inline-block; padding:16px 40px; background: #FCB131; border-radius: 5px; font-family: 'Work Sans'; font-style: normal; font-weight: 600; font-size: 18px; line-height: 21px; text-align: center; text-transform: uppercase; color: #191919; }
.finddeveloper a:hover, .finddeveloper a:focus { background: #000; color: #fff;}


.yourcvload { background: linear-gradient(277.55deg, #CFB4FF -43.53%, #FFB9C3 161.08%); height: 530px; position: relative; } 
.yourcvload .container { display: flex; align-items: center; align-content: center; height: 100%; } 
.jobfinder h2 { font-family: 'Work Sans'; font-style: normal; font-weight: 600; font-size: 38px; line-height: 50px; text-transform: capitalize; color: #191919; } 
.jobfinder p {margin: 0 0 30px;font-family: 'Work Sans'; font-style: normal; font-weight: 400; font-size: 18px; line-height: 30px; color: #525252; }
.jobfinderbg { position: absolute; right: 0; bottom: 0; }


.awardwining { padding: 70px 0; background: url(../public/images/awardbannerimg01.png)0 0  no-repeat;background-size: cover; } 
.awardtitle {margin: 0 0 25px; font-family: 'Work Sans'; font-style: normal; font-weight: 400; font-size: 18px; line-height: 30px; /* identical to box height, or 167% */ text-align: center; color: #142026; } 
.awardwininglogos ul { text-align: center; padding: 0; max-width: 1070px; margin: auto; } 
.awardwininglogos ul li { display: inline-flex; width: 23%; margin: 0 1% 10px; background: #FFFFFF; box-shadow: 0px 0px 30px rgba(2, 27, 40, 0.18); border-radius: 10px; height: 110px; align-items: center; align-content: center; justify-content: center; vertical-align: top; transition: all ease .3s;}
.awardwininglogos ul li:hover, .awardwininglogos ul li:focus { box-shadow: 0px 0px 60px 0 rgba(0,0,0,0.3); transition: all ease .3s;}


footer { padding: 70px 0 0; background: #191919; } 
.footleft p { font-family: 'Work Sans'; font-style: normal; font-weight: 400; font-size: 16px; line-height: 26px; /* or 162% */ color: #FFFFFF; margin: 16px 0 0; padding: 0 70px 0 0; } 
.footlink h2, .getintouch h2 { font-family: 'Work Sans'; font-style: normal; font-weight: 600; font-size: 26px; line-height: 30px; text-transform: capitalize; color: #FFFFFF; margin: 0 0 20px; } 
.footlink ul li { font-family: 'Work Sans'; font-style: normal; font-weight: 400; font-size: 18px; line-height: 30px; /* or 167% */ color: #FFFFFF; margin: 0 0 10px; } 
.footlink ul li a { color: #fff; } 
.footlink ul li a:hover,.footlink ul li a:focus { color: #F72585; } 
.footlink ul { padding: 0; } 
.getintouch ul { padding: 0; } 
.getintouch ul li { display: inline-block; vertical-align: middle; margin: 0 10px 0 0; } 
.getintouch ul li a { display: flex; width: 40px; height: 40px; background: #F72585; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.18); border-radius: 5px; align-items: self-end; align-content: center; justify-content: center; } 
.getintouch ul li a img {display: flex;align-items: center;}

.copyright { border-top: 1px solid rgba(255, 255, 255, 0.1); padding: 20px 0; margin: 40px 0 0; font-family: 'Work Sans'; font-style: normal; font-weight: 400; font-size: 16px; line-height: 19px; color: #9d9d9d; text-align: center; }

.getintouch ul li a:hover, 
.getintouch ul li a:focus { background: #7B3FE4; }



/* About */
.industries { padding:40px 0 20px; background: url(../public/images/industrybannerimg01.png) 0 0 no-repeat;background-size: cover; }
.industrieslogo .slick-list { padding: 20px 0 10px; }
.lookgreadtsec { padding: 70px 0; background: #EEEEEE; } 
.lookgreatcnt h2 { font-family: 'Work Sans'; font-style: normal; font-weight: 600; font-size: 58px; line-height: 68px; /* identical to box height */ text-transform: capitalize; /* h1 */ color: #191919; margin: 30px 0 10px; } 
.lookgreatcnt p { font-family: 'Work Sans'; font-style: normal; font-weight: 400; font-size: 18px; line-height: 30px; /* or 167% */ /* h2 */ color: #525252; margin: 0 0 30px; }
.induslogobox img {
    max-height: 27px;
}

.approachsec { padding: 70px 0; } 
.approachsec ul { padding: 0; display: flex; align-items: center; align-content: center; flex-wrap: wrap; justify-content: center; } 
.approachsec ul li { display: inline-flex; width: 18%; flex-wrap: wrap; margin: 0 1% 10px; justify-content: center; align-items: flex-start; align-content: flex-start; } 
.approachsec ul li strong { display: block; width: 100%; font-family: 'Work Sans'; font-style: normal; font-weight: 600; font-size: 60px; line-height: 70px; text-align: center; text-transform: capitalize; background: linear-gradient(92deg, #F72585 0.55%, #FCB131 97.36%); -webkit-background-clip: text; -webkit-text-fill-color: transparent; background-clip: text; text-fill-color: transparent; } 
.approachsec ul li span { font-family: 'Work Sans'; font-style: normal; font-weight: 500; font-size: 16px; line-height: 19px; /* identical to box height */ text-transform: uppercase; /* h2 */ color: #525252; text-align: center; }


.corevalue { background: #191919; padding: 70px 0; } 
.corevalue h2 { font-family: 'Work Sans'; font-style: normal; font-weight: 600; font-size: 36px; line-height: 42px; text-transform: capitalize; color: #FFFFFF; text-align: center; margin: 0 0 10px; } 
.corevalue h4 { font-family: 'Work Sans'; font-style: normal; font-weight: 400; font-size: 18px; line-height: 30px; /* or 167% */ text-align: center; color: #E6EFF3; margin: 0 0 40px; } 
.corevalue ul { display: flex; align-items: center; align-content: center; padding: 0; flex-wrap: wrap; } 
.corevalue ul li { position: relative; display: inline-flex; width: 32%; margin:0.5%; padding: 15px; height: 285px; align-items: center; align-content: center; justify-content: center; flex-wrap: wrap; transition: all ease .3s; } 

.corevalue ul li:hover, .corevalue ul li:focus { background: #2B2B2B; transition: all ease .3s;}


.corevalue ul h3 { font-family: 'Work Sans'; font-style: normal; font-weight: 500; font-size: 22px; line-height: 26px; /* identical to box height */ text-align: center; text-transform: capitalize; color: #FFFFFF; width: 100%; margin: 20px 0 10px; } 
.corevalue ul li p { font-family: 'Work Sans'; font-style: normal; font-weight: 300; font-size: 16px; line-height: 25px; /* or 156% */ text-align: center; color: #B1C8D5; }


.corevalue ul li:after { content: ''; border-bottom: 1px solid #2B2B2B; width: 96%; height: 1px; position: absolute; left: 0; bottom: 0; margin: 0 auto; } 
.corevalue ul li::before { content: ''; border-right: 1px solid #2B2B2B; width: 1px; height: 96%; position: absolute; right: 0; top: 0; margin: auto; }


.corevalue ul li:nth-child(3):before, .corevalue ul li:last-child:before { display: none; } 
.corevalue ul li:nth-child(4):after, .corevalue ul li:nth-child(5):after { display: none; }

.corevalue ul li:last-child:after { display: none;}

.webelive { padding: 70px 0; } 
.believetitle { font-family: 'Work Sans'; font-style: normal; font-weight: 400; font-size: 18px; line-height: 30px; text-align: center; color: #191919; margin: 0 0 50px; } 
.believebox { display: flex; align-items: center; align-content: center; justify-content: space-between; } 
.beliveboxcnt { width: 37%; } 
.belivelisting { position: relative;display: inline-flex; flex-wrap: wrap; margin: 0 0 60px;border-radius: 10px; padding: 25px 25px 10px 15px; transition: all ease .3s;} 

.belivelisting:last-child { margin: 0; }
.believeimg { text-align: center; width: 34%; } 
.belivelisting h3 { font-family: 'Work Sans'; font-style: normal; font-weight: 600; font-size: 30px; line-height: 35px; text-transform: capitalize;color: #00A651; width: 100%; position: relative; margin: 0 0 16px; } 
.belivelisting p { font-family: 'Work Sans'; font-style: normal; font-weight: 500; font-size: 16px; line-height: 26px; color: #525252; } 
.belivenum { position: absolute; top: -10px; right:15px; font-family: 'Work Sans'; font-style: normal; font-weight: 700; font-size: 88px; line-height: 103px; text-align: right; text-transform: capitalize; text-shadow: 0px 0px 2px #00000075; color: #fff; z-index: 0; } 
.beliveboxcnt.text-start .belivelisting h3 span { right: inherit; left: 0; }
.belivelisting:hover { background: #7B3FE4; transition: all ease .3s; }
.belivelisting:hover h3 { color: #fff; }
.belivelisting:hover p { color: #fff; }
.belivelisting:hover .belivenum { color: #7b3fe4; }
.beliveboxcnt.text-start .belivenum { right: inherit; left: 14px; }



.clientsay { background: #191919 url(./img/clientpatterimg.svg)0 0 no-repeat; background-size: cover; padding: 70px 0; } 
.clientsay h2 { font-family: 'Work Sans'; font-style: normal; font-weight: 600; font-size: 36px; line-height: 42px; text-transform: capitalize; color: #FFFFFF; text-align: center; margin: 0 0 20px; } 
.userdata h3 {margin: 15px 0 10px; font-family: 'Work Sans'; font-style: normal; font-weight: 600; font-size: 18px; line-height: 21px; text-align: center; text-transform: capitalize; color: #FFFFFF; } 
.userdata h4 {margin: 0 0 20px; font-family: 'Work Sans'; font-style: normal; font-weight: 300; font-size: 14px; line-height: 16px; text-align: center; text-transform: capitalize; color: #7091A3; } 
.userdata p {padding: 0 60px; font-family: 'Work Sans'; font-style: italic; font-weight: 400; font-size: 18px; line-height: 30px; text-align: center; color: #E6EFF3; }


.userdata { text-align: center; }
.clientimg { text-align: center; position: relative; display: inline-block; margin: auto; } 
.clientimg img { display: inline-block!important; max-width: 90px; max-height: 90px; object-fit: cover; }
.clientimg:before {display: inline-block; margin-right: 20px; width: 36px;height:26px; content: ''; background: url(./img/commenticonleft.svg)0 0 no-repeat;}
.clientimg:after {display: inline-block; margin-left: 20px; width: 36px;height: 26px;content: ''; background: url(./img/commenticonright.svg)0 0 no-repeat;}



.slick-slide:hover, .slick-slide:focus { outline: none;}

.clientsayslider button.slick-prev { width: 90px; height: 90px; background: url(./img/sliderleftarrow.svg)0 0 no-repeat; z-index: 2;}

.clientsayslider button.slick-next { width: 90px; height: 90px; background: url(./img/sliderrightarrow.svg)0 0 no-repeat; z-index: 2;}

.clientsayslider button.slick-arrow:before {display: none!important;}



/* Hire Developer */
.hiredevmain { padding: 70px 0; } 
.hiredevsec h2 { font-family: 'Work Sans'; font-style: normal; font-weight: 600; font-size: 58px; line-height: 68px; /* identical to box height */ text-transform: capitalize; /* h1 */ color: #191919; margin: 0 0 10px; } 
.hiredevsec p { font-family: 'Work Sans'; font-style: normal; font-weight: 400; font-size: 18px; line-height: 30px; /* or 167% */ /* h2 */ color: #525252; margin: 0 0 30px; } 
.hireemail { position: relative; max-width: 540px;} 

.hireemail input { width: 100%; border: 1px solid #525252; border-radius: 5px; padding:16px 190px 16px 12px; font-family: 'Work Sans'; font-style: normal; font-weight: 400; font-size: 18px; line-height: 21px; /* h2 */ color: #525252; } 


.hireemail input::-webkit-input-placeholder,
.hireemail textarea::-webkit-input-placeholder{color:#525252}
.hireemail input:-moz-placeholder,
.hireemail textarea:-moz-placeholder{color:#525252}
.colorbg { position: absolute; left: 0; top: 0; z-index: 0; }
button.hirenow { background: #7B3FE4; border-radius: 0px 5px 5px 0px; border: none; padding: 14px 40px; position: absolute; right: 0; top: 0; font-family: 'Work Sans'; font-style: normal; font-weight: 500; font-size: 18px; text-align: center; text-transform: uppercase; color: #FFFFFF; } 
.looking { font-family: 'Work Sans'; font-style: normal; font-weight: 400; font-size: 18px; line-height: 21px; color: #191919; margin: 30px 0 0; }
.looking button {
    color: #7B3FE4;
    background: transparent;
    padding: 0;
    border: none;
}
.hiredevsec .container { position: relative; z-index: 1; }
.hiredevsec { position: relative; padding: 70px 0; min-height: 550px;background:url(../public/images/findhireexpbg.svg)0 0 no-repeat; background-size: cover; }
.operatorimg { position: absolute; right: 0; top: 70px; }
.workbestsec { padding: 70px 0; }
.bestworkbox p { font-family: 'Work Sans'; font-style: normal; font-weight: 400; font-size: 18px; line-height: 30px; color: #525252; } 
.bestworkbox h2.blueheading { text-align: left; } 
.bestworklist ul { padding: 0; margin: 0; list-style: disc; } 
.bestworklist ul li { margin: 0 0 10px; } 
.bestworklist ul li a { font-family: 'Work Sans'; font-style: normal; font-weight: 500; font-size: 16px; line-height: 30px; color: #191919; } 
.bestworklist ul li a:hover, .bestworklist ul li a:focus { color: #ee334e; } 
.bestworklist ul li { position: relative; display: inline-block; padding: 0 0 0 16px; vertical-align: top; width: 48%; margin: 0; } 
.bestworklist ul li:before { content: '.'; position: absolute; left: 0; top: 3px; font-size: 36px; color: #000; line-height: 0px; }
.bestworkbox { margin: 80px 0 0; }
.getquote a,.getquote button { display: inline-block; padding: 13px 20px; background: #7B3FE4; border-radius: 5px; font-family: 'Work Sans'; font-style: normal; font-weight: 500; font-size: 18px; line-height: 21px; text-align: center; text-transform: uppercase; color: #FFFFFF; border: none;} 
.getquote a:hover, .getquote a:focus,.getquote button:hover { background: #000; color: #fff;}
.getquote { margin: 30px 0 0; }
.hiredevsec .col-md-6 { padding-top: 100px; }

.findprice { background: #F3EDFD; padding: 70px 0; } 
.pricesec {min-height: 400px; position: relative; background: #FFFFFF; box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.2); } 
.pricesec h2 { font-family: 'Work Sans'; font-style: normal; font-weight: 600; font-size: 23px; line-height: 27px; text-transform: capitalize; /* h1 */ color: #191919; margin: 0 0 10px; border-bottom: 1px solid #ECEBF8; padding: 20px 30px; } 
.pricecontent { padding: 10px 20px 20px 20px; } 

.findprice > h2 { margin: 0 0 40px; }
.pricecontent ul { padding: 0;margin: 20px 0 30px; } 
.findcontri { position: absolute; bottom: 20px; }

.pricecontent ul li { font-family: 'Work Sans'; font-style: normal; font-weight: 500; font-size: 16px; line-height: 26px; color: #525252; padding: 0 0 0 23px; margin: 0 0 10px; background: url(./img/greedotcheck.svg)0 5px no-repeat; } 
.pricecontent h3 { font-family: 'Work Sans'; font-style: normal; font-weight: 600; font-size: 48px; line-height: 56px; text-transform: capitalize;color: #7B3FE4; } 
.pricecontent h3 span { margin: 0 0 0 -10px;font-family: 'Work Sans'; font-style: normal; font-weight: 400; font-size: 16px; line-height: 19px; color: #767676; } 
.findcontri a,.findcontri button { border: 1px solid #EE334E; border-radius: 5px; display: inline-block; padding: 10px 20px; font-family: 'Work Sans'; font-style: normal; font-weight: 500; font-size: 15px; line-height: 18px; text-transform: uppercase; /* red */ color: #EE334E;background: transparent; }


/* .findcontri { margin: 0 0 20px; }  */
.pricesec.active {background: linear-gradient(149.93deg, #7B3FE4 0%, #AE80FF 100%);
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.2); } 
.pricesec:hover {background: linear-gradient(149.93deg, #7B3FE4 0%, #AE80FF 100%);
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.2); } 
.pricesec:hover h2 { color: #fff; } 
.pricesec:hover .pricecontent h3 { color: #fff; } 
.pricesec:hover .pricecontent h3 span { color: #fff; } 
.pricesec:hover .pricecontent ul li { color: #fff; } 
.pricesec:hover .findcontri a,.pricesec:hover .findcontri button { background: #F72585;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.18);
  border-radius: 5px;color: #fff; }

.pricesec:hover .pricecontent ul li {background: url(./img/whdote.svg)0 5px no-repeat;}

.selecttopsec { padding: 70px 0; background: url(../public/images/howselectbg.svg)0 0 no-repeat; background-size: cover; } 
.topselectionlist ul { padding: 0; } 
.passrate {padding: 9px 0 0; font-family: 'Work Sans'; font-style: normal; font-weight: 500; font-size: 18px; line-height: 21px; letter-spacing: 0.1em; text-transform: uppercase; /* green */ color: #00A651; width: 20%; border-right: 2px solid #7B3FE4; position: relative; } 
.topselectionlist ul li { display: flex; align-items: stretch; align-content: flex-start; justify-content: space-between; } 
.selectiondata { width: 73%; padding: 0 0 40px; } 
.passrate:after {background: #fff; width: 40px; height: 40px; border: 2px solid #7B3FE4; content: ''; position: absolute; right: -20px; top: 0; border-radius: 60px; } 
.passrate:before { width: 20px; height: 20px; content: ''; position: absolute; right: -10px; top: 10px; border-radius: 60px; background: #7B3FE4; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);z-index: 1; } 
.selectiondata strong { font-family: 'Work Sans'; font-style: normal; font-weight: 500; font-size: 18px; line-height: 21px; text-transform: capitalize; color: #191919; margin: 0 0 10px; display: block; } 
.selectiondata p { font-family: 'Work Sans'; font-style: normal; font-weight: 400; font-size: 16px; line-height: 24px; color: #525252; margin: 0; } 
.topselectionlist { margin: 40px 0 0; }

.selecttopsec h2.blueheading { text-align: left; }


.hiretime { padding: 70px 0; background: #191919; } 
.hiretimeheading { font-family: 'Work Sans'; font-style: normal; font-weight: 600; font-size: 36px; line-height: 42px; text-transform: capitalize; color: #FFFFFF; text-align: center; margin: 0 0 10px; } 
.hiretitle { font-family: 'Work Sans'; font-style: normal; font-weight: 400; font-size: 18px; line-height: 30px; text-align: center; color: #E6EFF3; margin: 0 0 30px; } 
.hireprocess {height: 220px; background: #3939393d; border-radius: 10px; margin: 0 0 30px; display: flex; align-items: flex-start; align-content: flex-start; padding: 20px; justify-content: space-between; position: relative; } 
.hireprocessicon { width: 16%; background: #191919; box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1); border-radius: 10px; height: 90px; display: flex; align-items: center; align-content: center; justify-content: center; } 
.hireprocesscnt { width: 80%;z-index: 1;} 
.hireprocesscnt h3 { font-family: 'Work Sans'; font-style: normal; font-weight: 600; font-size: 18px; line-height: 21px; text-transform: capitalize; color: #FFFFFF; } 
.hireprocesscnt p {font-family: 'Work Sans'; font-style: normal; font-weight: 400; font-size: 16px; line-height: 26px; color: #C9D6DE; margin: 0; padding: 0 20px 0 0; } 

.processcount { position: absolute; right: 5px; bottom: 5px; font-family: 'Work Sans'; font-style: normal; font-weight: 700; font-size: 100px; line-height: 117px; text-align: right; text-transform: capitalize; text-shadow: 0px 0px 2px #b0b0b04a; color: #202020; z-index: 0; }


.benefitofhiring { padding: 70px 0 40px; } 
.benefitofhiring ul li { display: inline-flex; width: 31%; margin: 0 1% 30px; background: #FFFFFF; box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.12); padding: 20px 10px; text-align: center; height: 220px; align-items: center; align-content: center; justify-content: center; flex-wrap: wrap; } 
.benefitofhiring ul li:hover, .benefitofhiring ul li:focus { box-shadow: 0 0 25px 2px rgb(0 0 0 / 20%); transition: all ease .3s; }

.benefitofhiring ul { display: flex; flex-wrap: wrap; justify-content: center; padding: 30px 0 0 0; } 
.benefitofhiring ul li p { font-family: 'Work Sans'; font-style: normal; font-weight: 400; font-size: 18px; line-height: 24px; text-align: center; color: #525252; width: 100%; } 
.benefitofhiring ul li img { margin-bottom: 10px; }

.postyourcv {padding: 100px 0; background-size: cover; text-align: center; background: url(../public/images/postcvbg.svg)0 0 no-repeat; background-size: cover;}

.postyourcv h2 { font-family: 'Work Sans'; font-style: normal; font-weight: 600; font-size: 36px; line-height: 42px; text-align: center; text-transform: capitalize; color: #FFFFFF; margin: 0 0 10px; } 
.postyourcv p { font-family: 'Work Sans'; font-style: normal; font-weight: 400; font-size: 18px; line-height: 30px; /* identical to box height, or 167% */ text-align: center; color: #E6EFF3; }


.readbtn { display: inline-block; padding: 16px 37px; background: #F72585; border-radius: 5px; font-family: 'Work Sans'; font-style: normal; font-weight: 500; font-size: 20px; line-height: 23px; text-align: center; text-transform: uppercase; color: #FFFFFF;border: none; }
.readbtn:hover, .readbtn:focus { background: #000; color: #fff;}

.faqsec h3 { font-family: 'Work Sans'; font-style: normal; font-weight: 400; font-size: 18px; line-height: 30px; text-align: center; color: #191919; }


.faqsec { padding: 70px 0 10px; } 
.faqsec h3 { font-family: 'Work Sans'; font-style: normal; font-weight: 400; font-size: 18px; line-height: 30px; text-align: center; color: #191919; } 
.faqlist .accordion-item { border: none; background: #FFFFFF; box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.12); margin: 0 0 15px; } 
.faqlist h2.accordion-header button { font-family: 'Work Sans'; font-style: normal; font-weight: 500; font-size: 22px; line-height: 24px; color: #191919; } 
.faqlist h2.accordion-header button.accordion-button:hover, 
.faqlist h2.accordion-header button.accordion-button:focus, 
.faqlist .accordion-button:not(.collapsed) {background: none; outline: none; box-shadow: none; }

.faqlist .accordion-body {padding-top: 0;font-family: 'Work Sans'; font-style: normal; font-weight: 400; font-size: 15px; line-height: 24px; color: #191919; }

.faqlist .accordion { max-width: 1100px; margin: auto; }

/* Search Result */
.bestworklist ul li:before { content: '.'; position: absolute; left: 0; top: 3px; font-size: 36px; color: #000; line-height: 0px; } 
/* .searchhead { background: #7B3FE4; padding: 40px 0 70px; text-align: center; } 
.searchhead h2 { font-family: 'Work Sans'; font-style: normal; font-weight: 600; font-size: 48px; line-height: 56px; text-align: center; text-transform: capitalize; color: #FFFFFF; margin: 0 0 10px; } 
.searchhead p { font-family: 'Work Sans'; font-style: normal; font-weight: 400; font-size: 18px; line-height: 30px; text-align: center; color: #FFFFFF; } */

.bestworklist ul li:before { content: '.'; position: absolute; left: 0; top: 3px; font-size: 36px; color: #000; line-height: 0px; } 
.searchhead { background: #160B29; padding: 40px 15px 70px; text-align: center; } 
.searchhead h2 { font-family: 'Work Sans'; font-style: normal; font-weight: 600; font-size: 48px; line-height: 56px; text-align: center; text-transform: capitalize; color: #FFFFFF; margin: 0 0 10px; } 
.searchhead p { font-family: 'Work Sans'; font-style: normal; font-weight: 400; font-size: 18px; line-height: 30px; /* or 167% */ text-align: center; color: #FFFFFF; } 
.searchform .devform { background: #FFFFFF; box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.12); border-radius: 10px; padding: 30px 50px; display: flex; align-items: center; align-content: center; justify-content: space-between; } 
.searchform .devform ul { padding: 0; margin: 0; width: 90%; } 
.searchform { margin: -50px 0 50px 0; } 
.searchform .devform ul li { display: inline-block; width: 31%; margin: 0 1%; } 
.searchform .devform ul li select { background: #FFFFFF; border: 1px solid #A4BDCB; border-radius: 5px; width: 100%; padding: 14px 10px; font-family: 'Work Sans'; font-style: normal; font-weight: 400; font-size: 16px; line-height: 19px; color: #767676;} 
button.searchbutton { width: 10%; background: #7B3FE4; border-radius: 5px; border: none; padding: 12px 8px; font-family: 'Work Sans'; font-style: normal; font-weight: 400; font-size: 18px; line-height: 27px; text-align: center; color: #FFFFFF; }
button.searchbutton:hover, button.searchbutton:focus { background: #000; color: #fff;}
.alldeveloperlisting { padding: 70px 0; } 
.developerdetail { background: #FFFFFF; box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.12); border-radius: 10px; padding: 30px; margin: 0 0 30px; }
.alldeveloperlisting { padding:20px 0 40px; } 
.developerdetail { background: #FFFFFF; box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.12); border-radius: 10px; padding: 30px; margin: 0 0 30px; } 
.devnameimg { display: flex; align-items: center; align-content: center; justify-content: space-between; } 
.devnamedetail { display: flex; align-items: center; align-content: center; } 
.devcnt { padding: 0 0 0 10px; }
.experience img { display: inline-block; margin-right: 5px; vertical-align: middle; }
.devcnt h2 { font-family: 'Roboto'; font-style: normal; font-weight: 600; font-size: 18px; line-height: 21px;  text-transform: capitalize; color: #041B28;margin: 0; } 
.devcnt h3 { font-family: 'Roboto'; font-style: normal; font-weight: 400; font-size: 13px; line-height: 15px; color: #767676; }
button.resumebtn { background: #FCB131; border-radius: 3px; border: none; padding: 7px 13px; font-family: 'Roboto'; font-style: normal; font-weight: 400; font-size: 16px; line-height: 19px; text-align: center; color: #FFFFFF; } 
button.resumebtn img { display: inline-block; vertical-align: middle; margin: 0 5px 0 0; }
.developerdetail address p { font-family: 'Roboto'; font-style: normal; font-weight: 400; font-size: 14px; line-height: 16px; color: #767676; } 
.developerdetail address p span { display: inline-block; vertical-align: middle; margin: 0 5px 0 0; } 
.developerdetail address { margin: 10px 0 0; }
.experience { background: #ee334e26; border-radius: 5px; display: inline-block; padding: 5px 10px; margin: 10px 0 0;text-transform: capitalize; } 
.devqulification { margin: 20px 0 0; } 
.devqulification span { display: inline-block; vertical-align: middle; margin: 0 5px 0 0; border: 1px solid #ECEBF8; border-radius: 3px; padding: 5px 8px; font-family: 'Roboto'; font-style: normal; font-weight: 400; font-size: 12px; line-height: 14px; text-align: center; color: #767676; } 
.devqulification { margin: 20px 0 16px; }
button.hirenowbtn { border: 1px solid #7B3FE4; border-radius: 5px; background: #fff; padding: 7px 20px; font-family: 'Roboto'; font-style: normal; font-weight: 400; font-size: 18px; line-height: 21px; text-align: center; color: #7B3FE4; } 
button.hirenowbtn:hover, button.hirenowbtn:focus { background: #7B3FE4; color: #fff; }
.candidatematch { padding: 70px 10px; background: #191919; text-align: center; margin: 0 0 0; } 
.candidatematch h2 { font-family: 'Work Sans'; font-style: normal; font-weight: 600; font-size: 36px; line-height: 42px; text-transform: capitalize; color: #FFFFFF; margin: 0 0 10px; } 
.candidatematch h3 { font-family: 'Work Sans'; font-style: normal; font-weight: 500; font-size: 22px; line-height: 26px; text-align: center; color: #FFFFFF; } 
.upgrageplanbtn a,.upgrageplanbtn button { background: #EE334E; border-radius: 5px; padding: 15px 20px; font-family: 'Work Sans'; font-style: normal; font-weight: 500; font-size: 18px; line-height: 21px; text-align: center; text-transform: uppercase; color: #FFFFFF;border: none; } 
.upgrageplanbtn_box { margin: 40px 0 0; } 
.upgrageplanbtn a:hover, .upgrageplanbtn a:focus,.upgrageplanbtn button:hover { background: #7B3FE4; color: #fff; }
.getdoteleft { position: absolute; left: -84px; top: 60px; } 
.getdoteright { position: absolute; right: -108px; top: 60px; } 
.projectsteps .container { position: relative; }
.recruitsec .container { position: relative; } 
.recdoteright { position: absolute; right: -80px; bottom: -75px; z-index: -1; }
.innerbanner { padding: 90px 15px; background: #160B29; text-align: center; } 
.innerbanner h2 { font-family: 'Work Sans'; font-style: normal; font-weight: 600; font-size: 44px; line-height: 52px; text-transform: capitalize; color: #FFFFFF; } .innerbanner h2 span { color: #F72585; } 
.innerbanner h3 { font-family: 'Work Sans'; font-style: normal; font-weight: 600; font-size: 22px; line-height: 30px; color: #FFFFFF; }
.blogsec { padding: 70px 0; } 
.bloglisting {margin: 0 0 25px; background: #FFFFFF; box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.08);  transition: all ease .3s;} 
.bloglisting:hover { box-shadow: 0 0 30px 0 rgb(0 0 0 / 25%); transition: all ease .3s; }
.blogimg img { width: 100%; } 
.titleread { display: flex; align-items: center; align-content: center; justify-content: space-between; margin: 0 0 13px; } 
.greentitle { background: rgba(0, 166, 81, 0.3); border-radius: 5px; padding: 3px 10px; font-family: 'Work Sans'; font-style: italic; font-weight: 400; font-size: 16px; line-height: 19px; text-transform: capitalize; color: #00A651; } .totalread { font-family: 'Work Sans'; font-style: italic; font-weight: 400; font-size: 16px; line-height: 19px; text-align: right; text-transform: capitalize; color: #666666; } 
.writtenby a { font-family: 'Work Sans'; font-style: italic; font-weight: 400; font-size: 16px; line-height: 19px; text-transform: capitalize; color: #666666; margin: 0 22px 0 0; position: relative; } 
.writtenby a:last-child { margin: 0;}
.writtenby a:after { content: "."; position: absolute; font-size: 34px; color: #666666; right: -15px; top: -4px; line-height: 10px; } 
.writtenby a:last-child::after {display: none;}
.writtenby { margin: 0 0 10px; } 
.blogdata { padding: 15px 15px 15px 15px;background: #fff; } 
.blogdata h3 {margin: 0; font-family: 'Work Sans'; font-style: normal; font-weight: 500; font-size: 16px; line-height: 22px; text-transform: capitalize; color: #191919; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis; } 
.blogdata h3 a {color: #191919;}
.blogdata h3 a:hover, .blogdata h3 a:focus {color: #000;}
.blogdata p { margin: 0; font-family: 'Work Sans'; font-style: normal; font-weight: 400; font-size: 16px; line-height: 28px; color: #525252;display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis; }
.blogbig .blogdata h3 {margin: 0 0 10px; font-weight: 600; font-size: 24px; line-height: 28px;}

.blogbig .blogimg img { max-height: 430px; object-fit: cover; } 
.blogimg img { max-height: 177px; object-fit: cover; width: 100%; }
.blogbig .blogdata { padding: 30px 20px 10px 20px; }
.blogbig .titleread { margin: 0 0 20px; }
.paginations { text-align: center; } 
.paginations ul { padding: 0; margin: 0; } 
.paginations ul li { display: inline-block; vertical-align: middle; margin: 0 12px 0 0; } 
.paginations ul li a { display: block;padding: 6px 16px; border: 1px solid #999999; border-radius: 3px; background: no-repeat; font-family: 'Work Sans'; font-style: normal; font-weight: 400; font-size: 16px; line-height: 19px; color: #999999; } 
.paginations ul li.active a, .paginations ul li a:hover, .paginations ul li a:focus { background: #7B3FE4; color: #fff; border-color: #7B3FE4; }
/* Bgcolor Effect */
.bgeffectblogsec { width: 100%; height: 100%; min-height: 100%; overflow: hidden; }
.bgeffectblog1{position: absolute;width: 230px; height: 230px;left: 50px; top: 278px;background: #00A651; filter: blur(12.17rem);z-index: -1;}
.bgeffectblog2{position: absolute;width: 230px; height: 230px;left: 1140px; top: 390px;background: #FCB131; filter: blur(12.17rem);z-index: -1;}
.bgeffectblog3{position: absolute;width: 230px; height: 230px;left: 1176px; top: 2886px;background: #EE334E; filter: blur(12.17rem);z-index: -1;}
.bgeffectblog4{position: absolute;width: 230px; height: 230px;left: 93px; top: 2257px;background: #00A651; filter: blur(12.17rem);z-index: -1;}
.bgeffectblog5{position: absolute;width: 230px; height: 230px;left: 972px; top: 1900px;background: #EE334E; filter: blur(12.17rem);z-index: -1;}
.bgeffectblog6{position: absolute;width: 230px; height: 230px;left: 68px;top: 1328px;background: #7B3FE4; filter: blur(12.17rem);z-index: -1;}
.bgeffectblog7{position: absolute;width: 230px; height: 230px;left: 1108px; top: 1098px;background: #EE334E; filter: blur(300px);z-index: -1;}
.blogmain { background: url(../public/images/gradientbgnew.webp)0 0 no-repeat; background-size: cover;}
.blogdetail { padding: 90px 0; } 
.blogdetail .container { max-width: 995px; } 
.blogsingleimg img { width: 100%; } 
.blogsingleimg { margin: 0 0 20px; } 
.postby { text-align: center; margin: 0 0 20px; } 
.postby img { height: 82px; width: 82px; object-fit: cover; } 
.postby span { display: block; width: 100%; font-family: 'Work Sans'; font-style: normal; font-weight: 500; font-size: 16px; line-height: 19px; text-transform: capitalize; color: #666666; margin: 5px 0 0; } 
.blogpostdetail { position: relative; padding: 0 0 0 100px; } 
.blogdate { position: absolute; left: 0; top: 20px; } 
.blogdate strong { border-top: 1px solid #00A651; padding: 10px 10px 0 10px; font-family: 'Work Sans'; font-style: normal; font-weight: 500; font-size: 36px; line-height: 42px; text-transform: capitalize; color: #4D4D4D; } 
.blogdate span { font-family: 'Work Sans'; font-style: italic; font-weight: 400; font-size: 14px; line-height: 16px; text-transform: capitalize; color: #999999; display: block; width: 100%; } 
.postdescription h2 { font-family: 'Work Sans'; font-style: normal; font-weight: 600; font-size: 36px; line-height: 42px; text-transform: capitalize; color: #191919; margin: 0 0 10px; } 
.postdescription p { font-family: 'Work Sans'; font-style: normal; font-weight: 400; font-size: 16px; line-height: 28px; color: #525252; margin: 0 0 20px; } 
.postdescription h3 { font-family: 'Work Sans'; font-style: normal; font-weight: 600; font-size: 22px; line-height: 26px; text-transform: capitalize; color: #191919; } 
.postdescription img { width: 100%; margin: 0 0 20px; }
.similarblog { padding: 0px 0 80px;}
.similarblog h2 {font-family: 'Work Sans'; font-style: normal; font-weight: 600; font-size: 36px; line-height: 42px; color: #7B3FE4; text-align: center; margin: 0 0 40px;}
.similarblog h2 span {color: #F72585;}

/* Portfolio */
.portfoliopage { padding:70px 0; }
.portlogosec { margin: 0 0 30px; box-shadow: 0 0 14px 0 rgb(0 0 0 / 10%); border-radius: 5px; }
.portfoliopage h2 { font-family: 'Work Sans'; font-style: normal; font-weight: 600; font-size: 36px; line-height: 42px; text-transform: capitalize; color: #7B3FE4; text-align: center; margin: 0 0 40px; }
.portfoliopage h2 span { color: #F72585;}
.portlogoimg {position: relative; background: #F5F5F5; border-radius: 5px 5px 0px 0px; padding: 20px 0; text-align: center; overflow: hidden; transition: all ease .3s; }
.portlogoimg a img { width: 100%; }
.portdesc { padding: 20px; } 
.portdesc h3 { font-family: 'Work Sans'; font-style: normal; font-weight: 600; font-size: 24px; line-height: 28px; text-transform: capitalize; color: #191919; margin: 0 0 3px; overflow: hidden; -o-text-overflow: ellipsis; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; }
.portdesc h3 a { color: #191919;}
.portdesc p { font-family: 'Work Sans'; font-style: normal; font-weight: 400; font-size: 16px; line-height: 26px; color: #525252; overflow: hidden; -o-text-overflow: ellipsis; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; }
.showmore { text-align: center;padding: 40px 0 0; } 
.showmore a { border: 1px solid #7B3FE4; border-radius: 5px; padding: 13px 23px; font-family: 'Work Sans'; font-style: normal; font-weight: 500; font-size: 18px; line-height: 21px; text-align: center; text-transform: uppercase; color: #7B3FE4;display: inline-block; }
.showmore a:hover, .showmore a:focus { background: #7B3FE4; color: #fff;}
.portlogosec:hover, .portlogosec:focus { box-shadow: 0 0 50px 0 rgb(0 0 0 / 20%); transition: all ease .3s; }

.overlaybox {background: linear-gradient(63.58deg, #F72585 -61.03%, #7B3FE4 159.44%); opacity: 0.8; position: absolute; left: 0; right: 0; bottom: -220px; display: flex; align-items: center; align-content: center; justify-content: center; width: 100%; height: 100%; transition: all ease .3s;
}

.overlaybox a {
  margin: 0 10px;
}
.portlogosec:hover .overlaybox, 
.portlogosec:focus .overlaybox { bottom: 0;}

/* portfolio detail */
.portfoliodetailmain { background: url(../public/images/gradientbgnew.webp)0 0 no-repeat; background-size: cover;}
.portfoliodetailbanner {
  height: 300px;
  background: #160B29;
  text-align: center;
}

.portfoliodetailbanner h2 {
  padding-top: 100px;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 50px;
/* identical to box height, or 139% */
  text-align: center;
  text-transform: capitalize;
  color: #FFFFFF;
}

.portdetailbanner {
  text-align: center;
  margin-top: -120px;
  position: relative;
  z-index: 1;
}

.portdetailbanner img {
  max-width: 790px;
  width: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.overviewproject h2 {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 42px;
    text-transform: capitalize;
    color: #F72585;
    text-align: center;
    margin: 0 0 10px;
}

.overviewproject {
    padding: 70px 0;
}

.overviewproject h2 span {
    color: #7B3FE4;
}

.overviewproject p {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #525252;
}


.projecdeslist {
  display: flex; align-items: flex-start; align-content: flex-end; justify-content: flex-start; flex-wrap: wrap; margin: 40px 0 0;
}

.protechnology {
    width: 48%;
}

.protechnology h3 {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    text-transform: capitalize;
    color: #F72585;
}
.protechnology h3 span { color: #7B3FE4;}
.protechnology ul {
  padding: 0;
  margin: 0;
}

.protechnology ul li {
  position: relative;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #525252;
  margin: 0 0 10px;
  padding: 0 0 0 30px;
}
.protechnology ul li:before {position: absolute; left: 0; top: 8px; width: 19px; height: 19px; content: ''; background: url(../public/images/greenarrow.png)0 0 no-repeat;}

.visitsitebnt {
  margin: 50px 0 0; text-align: center; width: 100%;
}

.visitsitebnt a {
  display: inline-block;
  background: #7B3FE4;
  border-radius: 5px;
  padding: 16px 26px;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
}

.visitsitebnt a img {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 0 10px;
}
.visitsitebnt a:hover, .visitsitebnt a:focus { background: #000;color: #fff;}
.scroll-to-top { right: 10px!important; bottom: 10px!important; }
/* end */

/* 27 Dec B  */
.go_top_btn { display: flex; width: 40px; height: 40px; background: #F72585; box-shadow: 0px 4px 4px rgb(0 0 0 / 18%); border-radius: 5px; align-items: center; justify-content: center; border: none; position: fixed; bottom: 15px; right: 15px; }

.updatetwitimg img { max-width: 100%; }

/* end */

.emailpop .modal-title { font-family: 'Work Sans'; font-style: normal; font-weight: 600; font-size: 22px; line-height: 26px; text-align: left; } 
.modal-header .btn-close { position: absolute; right: 10px; top: 10px; } 
.modal .modal-header { padding-right: 60px; padding-top: 30px; padding-left: 30px; border: none; padding-bottom: 0;} 
.modal .modal-body { padding: 30px; }
.candidatematch{border-bottom: 1px solid #333;}
.email_form .submit {
    text-transform: uppercase;
    position: absolute;
    right: 0;
    z-index: 33;
}
.email_form .form-control { box-shadow: none !important; border: 1px solid #ccc; width: 100% !important; height: 51px; border-radius: 0.375rem !important; padding-right: 100px; }
.hidebtn{display: none;}
.servicetitle { max-width: 845px; margin-left: auto; margin-right: auto; }
.whychoose .row { align-items: flex-end; }
.why_choose_cnt{padding-bottom: 50px;}
.jobfinder { padding-right: 30px; }
.error {color: red;font-size: 15px;line-height: 28px;}

.loader {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background: #0007;
    z-index: 999999;
}
.isLoading{display: flex;}
.loader .spinner-border {border-color: #F72585 !important;border-right-color: transparent !important; width: 40px;height: 40px;}
.portlogoimg > a > img { max-height: 120px; object-fit: contain; max-width: 180px; } .portlogoimg { height: 140px; display: flex; align-items: center; justify-content: center; }
.portdetailbanner { height: 400px; overflow: hidden; border-radius: 20px; max-width: 790px; margin-left: auto; margin-right: auto; box-shadow: 0 0 15px rgba(0,0,0,0.2); } 
.portdetailbanner:hover img { transform: translateY(calc(-100% + 400px)); transition: transform 9s ease-in-out,-webkit-transform 9s ease-in-out; }
button.resumebtn { display: inline-flex; align-items: center; }

.submit_cv { width: 100%; position: relative; } 
.cv_form form > div:not(:last-child) { margin-bottom: 20px; } 
.cv_form .form-control { box-shadow: none !important; border: 1px solid #ccc; width: 100% !important; height: 51px; border-radius: 0.375rem !important; } 
.cv_submit { background: #EE334E; border-radius: 5px; padding: 15px 20px; font-family: 'Work Sans'; font-style: normal; font-weight: 500; font-size: 18px; line-height: 21px; text-align: center; text-transform: uppercase; color: #FFFFFF; border: none;margin-left: auto; }
.submit_cv .uploadfile { position: absolute; top: 0; left: 0; width: 100%; opacity: 0; }

/* Captcha */
.captcha_box { max-width: 300px; display: flex;padding-right: 30px;position: relative; } 
.captchaimg { width: 120px; text-align: center; background: #ee334e29; color: #EE334E; font-size: 18px; font-style: italic; border-radius: 5px 0 0 5px; position: relative; border: none; }
.email_form .input-group { margin: 0 0 15px; } 
.captcha .captchaInput.form-control { border-radius: 0 5px 5px 0 !important; padding: 0.375rem 0.75rem; margin-left: -1px; width: calc(100% - 120px) !important; height: auto; height: 49px;border-left: none; }
.captcha label { font-size: 16px; font-weight: 400; color: #000; line-height: 25px; margin: 0 0 5px; }
.captcha_refresh { position: absolute; right: 0; top: 50%; transform: translateY(-50%); background: transparent; border: none; padding: 0; } 

/* 18 Jan 23 */
.searchform .devform .button.button-mat.btn--7 { padding-left: 10px; padding-right: 10px; min-width: 110px; }
.input-group{align-items: flex-start;}
.cv_form .input-group{align-items: flex-end;}
form .button-mat { border-radius: 0.3rem !important; margin-left: auto !important; }
/* New Form design */
.modalNew .modal-content { background: #160B29;box-shadow: 0 0 35px rgb(0 0 0 / 50%); } 
.modalNew .modal-title { color: #fff; } 
.modalNew .btn-close { background-color: #fff; opacity: 1; width: 30px; height: 30px; border-radius: 100%; right: 0; top: 0; background-size: 12px; padding: 0 !important; background-position: center; } 
.modalNew .form-control { background: transparent; border: 1px solid #fff; color: #fff; } 
.modalNew .form-control::placeholder{color: #fff;} 
.modalNew .form-control:focus{background: transparent !important;color: #fff !important;}
.modalNew .captcha label { color: #fff; } 
.modalNew .captchaimg { background: #7b3fe48f; color: #fff; } 
.modalNew .captcha_refresh img { filter: brightness(1) invert(1); }
.modalNew .btn-close:hover { opacity: 1; }
.email_form .submit.button.button-mat.btn--7 { height: 51px; border-top-left-radius: 0 !important; border-bottom-left-radius: 0 !important; box-shadow: none !important; right: -1px !important; }

.modalNew label { color: #fff !important; } 
.modalNew input { color: #fff; } 
.modalNew fieldset.MuiOutlinedInput-notchedOutline {border-width: 1px !important; border-color: #fff !important; }
.modalNew .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, .modalNew .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .modalNew .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root:hover .MuiOutlinedInput-notchedOutline, .modalNew .form-control:hover, .modalNew .form-control:focus, .submit_cv:hover .form-control, .css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{ border-color: #fff !important;border-width: 1px !important; }
.modalNew svg.MuiSvgIcon-root { fill: #fff !important; }
.modalNew .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused,.modalNew .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root,.css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root{color: #fff !important;}
.modalNew .button-mat .spinner-border { width: 20px; height: 20px; border-width: 2px; }
.button-mat:disabled {opacity: 0.7;cursor: not-allowed;}
.css-1wc848c-MuiFormHelperText-root.Mui-error{margin-left: 0;font-size: 14px;}
.devcnt h3 span:not(:last-child):after,.devdetails h5 span:not(:last-child):after { content: ', '; }

/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0px 1000px #0000 inset;
  transition: background-color 5000s ease-in-out 0s;
}
.searchform .devform .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input { padding: 13px 14px; font-size: 15px; } 
.searchform .devform .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root { font-size: 15px; }
.searchform .devform .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root{font-size: 15px;}
.deveimg img { width: 60px; height: 60px; object-fit: cover; border-radius: 100%; }
.devdetails { text-align: center; color: #fff; } 
.devdetails > img { width: 100px; height: 100%; object-fit: cover; border-radius: 100%; margin: 0 auto 10px; } 
.devdetails h3 { font-size: 22px; line-height: 27px; margin: 0 0 5px; } 
.devdetails h5 { font-size: 14px; font-weight: 400;margin-bottom: 7px; }
.hireform .modal-body { padding: 50px 30px; border-top: 1px solid #7B3FE4; margin-top: 20px; } 
.hireform .modal-header { padding-top: 20px; justify-content: center; }

.modalNew .submit_cv .form-control { height: 56px;}
.cvpopup .modal-header { justify-content: center; }

header .bluebtn { padding: 13px 24px;  color: #7B3FE4; margin-right: 10px; border: 1px solid #7B3FE4; }
header .bluebtn:hover,header .bluebtn:focus{border-color: #F72585;color: #fff;}
.topbtns .btn--7{box-shadow: none;}

.devform .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline,.devform .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root:hover .MuiOutlinedInput-notchedOutline { border-color: #7B3FE4 !important; border-width: 1px !important; } 
.devform .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused { color: #7B3FE4 !important; }
.searchform .devform ul li.disabled {
    pointer-events: none;
}

.error_img img { max-width: 700px;}
.error_img { text-align: center;}
.error_sec { padding: 40px 0;}
/*  */
.analysis_sec { padding: 70px 0; } 
.analysis_sec h2 + p { font-family: 'Work Sans'; font-style: normal; font-weight: 400; font-size: 18px; line-height: 27px; color: #525252; max-width: 700px; margin: 0 auto 30px; text-align: center; }
.analysis_table table.table {border-collapse: collapse;width: 100%;} 
.analysis_table table.table tr td:nth-of-type(even) { background: #7b3fe417; } 
.analysis_table table.table tr th:not(:first-child), .analysis_table table.table tr td:not(:first-child) { text-align: center; } 
.analysis_table table.table tr th,.analysis_table table.table tr td { padding: 14px 10px; font-family: 'Work Sans'; font-style: normal; font-weight: 400; font-size: 16px; line-height: 24px; color: #242424;vertical-align: middle; } 
.analysis_table { position: relative; background: #FFFFFF; box-shadow: 0px 4px 30px rgb(0 0 0 / 20%); } 
.analysis_table table.table tr th { font-weight: 600; color: #000; } 
.analysis_table table.table tr th:nth-of-type(even) { background: #7B3FE4; color: #fff; } 
.analysis_table table.table tr th:nth-of-type(odd):not(:first-child) { background: #F72585; color: #fff; } 
.analysis_table table.table tr td:nth-of-type(odd):not(:first-child) { background: #f7258526; } 
.analysis_table .table>:not(caption)>*>* { box-shadow: none !important; border-bottom: 1px solid #f7f7f7; } 
.analysis_table table.table tr td:first-child { font-weight: 500; color: #000; }
.analysis_table .table>:not(caption)>*:last-child > * { border-bottom: none; }
.hireform .MuiFormControl-root { width: 100%;}
.searchform .devform .css-q8hpuo-MuiFormControl-root:hover label { color: #7B3FE4 !important; }

.modalNew .MuiFormControl-root label { font-size: 15px; line-height: 100%; top: 6px; } 
.modalNew .MuiFormControl-root .MuiInputBase-input { font-size: 15px;color: #fff; }

.not_found img { max-width: 300px; } 
.not_found { text-align: center; padding-bottom: 60px; } 
.not_found h2 { font-family: 'Work Sans'; font-style: normal; font-weight: 600; font-size: 27px; line-height: 35px; text-align: center; text-transform: capitalize; }

ul.servicelist li { padding-bottom: 50px; } 
ul.servicelist li .viewdeveloper { position: absolute; bottom: 25px; }
.headerRight ul li a:hover { background: #fff; border-color: #fff; box-shadow: 0 0 5px #F72585; }


/* Responsive */
@media (min-width: 1200px){
  .container, .container-lg, .container-md, .container-sm, .container-xl{max-width: 1200px !important;}
}

@media(max-width:1199px){
ul.servicelist li { padding: 15px 15px 50px; }
ul.servicelist li h2 { font-size: 21px; line-height: 23px;}
.hiredevelopersec {margin-top: -320px;padding-bottom: 20px;}
.twitimgupdate2 {left: 138px;}
ul.servicelist::after { display: none;}
.jobfinderbg { max-width: 55%; }
.whychoose ul li { font-size: 18px; margin: 0 0 5px; }
.whychoose p { font-size: 16px; line-height: 23px; }
.blueheading { font-size: 30px; line-height: 35px;}
.whychoose .container .col-md-6:last-child { align-items: flex-end; align-content: flex-end; display: flex; }
.recruitcnt h2 { font-size: 29px; line-height: 35px;}
.recruitcnt p br { display: none; }
.jobfinder { position: relative; z-index: 1; }
.jobfinder p { padding-right: 60px; }
.footleft p { padding: 0; }
/* about */
.lookgreatcnt h2 { font-size: 38px; line-height: 43px; }
.lookgreatcnt h2 br { display: none;}
.approachsec ul li span { font-size: 13px;}
.approachsec { padding: 40px 0; }
/* Hiredeveloper */
.hiredevsec .col-md-6 { padding-top: 50px; }
.hiredevsec h2 { font-size: 40px; line-height: 46px; }
.hiredevsec p { font-size: 16px; line-height: 24px;}
.pricesec h2 { padding: 13px 20px; font-size: 23px;}
.pricecontent h3 { font-size: 40px; line-height: 46px;}
.pricecontent ul li { font-size: 15px; line-height: 23px;}
.findprice { padding: 50px 0;}
.passrate { width: 23%;;}

/* 10 Jan 23 B */
.wave__container { top: 14px; left: -40px; }
.searchform .devform ul li { width: 100%; margin: 0 0 12px !important; } 
.searchform .devform { flex-wrap: wrap; padding: 30px; } 
.searchform .devform ul { width: 100%; }
button.searchbutton{width: 100%;}

}

@media(max-width:991px){
.headerRight ul { margin: 0 10px 0 0; } 
.headerRight ul li { margin: 0 6px 0 0; } 
.headerRight ul li a { width: 35px; height: 35px; } 
.headerRight ul li a img { max-width: 14px; } 
.submitcvbtn { margin: 0 5px 0 0; padding: 8px 10px; font-size: 14px; } 
.bluebtn { padding: 8px 10px; font-size: 14px; } 
.headerRight { padding-right: 64px; } 
button.navbar-toggler { position: absolute; right: 12px; top: -71px; } 
button.navbar-toggler:hover, button.navbar-toggler:focus { outline: none; box-shadow: none;}
nav.navbar .container { position: relative; }    
nav.navbar.navbar-expand-lg { padding: 0;}
.navbar-collapse { position: absolute; right: 0; top: 0px; background: #fff; box-shadow: 0 1px 25px 0 rgb(0 0 0 / 10%); border-radius: 0; min-width: 180px;z-index: 2; } 
.menu a.nav-link { margin: 0; text-align: right; font-size: 14px; border-bottom: 1px solid #f2f2f2; padding: 10px 10px; } 
.menu a.nav-link:hover, .menu a.nav-link:focus, .menu a.nav-link.active { background: #7B3FE4; color: #fff; }
.mainbanner { padding: 40px 0 20px; }
.bannerimg { max-width: 380px;}
.bannercnt h1 { font-size: 40px; line-height: 40px;}
.bannercnt p br { display: none;}
.bannercnt p {margin:20px 0 20px;font-size: 16px; line-height: 22px;}

.twitimgupdate1 {width: 100px;}
.twitimgupdate2 { width: 100px;left: 117px; top: 20px;}
.twitimgupdate3 { width: 100px;}
.twitimgupdate4 { width: 130px;top: 200px; left: 0;}
.twitimgupdate5 { width: 130px;}

.hiredev { margin: 30px 0 0;}
.ourservsec { padding: 40px 0; }
.servicetitle { font-size: 15px; line-height: 25px;}
.servicetitle br { display: none;}
ul.servicelist li img { max-height: 50px; object-fit: contain; }
/* ul.servicelist li { height: 290px;} */
ul.servicelist li h2 { font-size: 17px; line-height: 20px; margin: 10px 0 4px; }
ul.servicelist li p { font-size: 15px; line-height: 23px; }
.viewdeveloper a { font-size: 14px;}
ul.servicelist li img { max-height: 50px; object-fit: contain; }
.projectstartbluesec {padding: 40px 10px 180px;}
.projectstartbluesec h2 { font-size: 30px; }
.projectstartbluesec p { font-size: 16px; line-height: 25px;}
.ourservsec { padding: 40px 0; } 
ul.servicelist li p { font-size: 15px; line-height: 23px; } 
ul.servicelist li img { max-height: 40px; object-fit: contain; } 
.projectstartbluesec h2 { font-size: 30px; } 
.stepcount { font-size: 21px; width: 50px; height: 50px; border-width: 2px; top: -20px; }
.prostepbox > img { max-width: 120px; max-height: 70px; }
.prostepbox p { font-size: 15px; line-height: 23px; }

.prostepbox > img { max-width: 120px; max-height: 70px; } 
.prostepbox p { font-size: 15px; line-height: 23px; } 
.hiredev { flex-wrap: wrap; } 
.notsatis { width: 100%; margin: 0 0 20px; text-align: center; } 
.hiredevimg { width: 100%; } 
.notsatis h2 { text-align: center; } 
.notsatis p { text-align: center; } 
.hiredevimg img { max-height: 370px; } 
.hiredevprocess { width: 100%; margin: 40px 0 0; } 
.devproceeicon { width: 13%; }
.hiredevlist { margin: 0 0 20px; }
.hiredevdesc h2 { font-size: 20px; margin: 0 0 4px; }
/* .hiredevelopersec { padding: 50px 0 20px; } */
.whychoose { padding: 40px 0 0; }
/* .industries { margin: 0 0 50px; } */
.recruitcnt h2 { font-size: 23px; line-height: 28px; margin: 0 0 3px; }
.recruitcnt p { margin: 0 0 10px; font-size: 15px; line-height: 25px; }
.finddeveloper a { padding: 11px 23px; font-size: 16px; }
.jobfinderbg { max-width: 50%; }
.jobfinder h2 { font-size: 29px; line-height: 35px; }
.jobfinder p { padding-right: 0; font-size: 15px; line-height: 25px; }
.awardwining { padding: 40px 0; }
.recruitsec { padding: 40px 0; }
.yourcvload { height: 380px; }
.footlink h2, .getintouch h2 { font-size: 21px; line-height: 28px; margin: 0 0 16px; }
.getintouch ul li a { width: 32px; height: 32px; } 
.getintouch ul li a img { max-width: 25px; }
footer { padding: 40px 0 0; }
.footlink ul li { font-size: 16px;}
.footleft p { font-size: 14px; line-height: 22px; }
/* about */
.lookgreadtsec { padding: 40px 0;}
.lookgreatcnt h2 { font-size: 29px; line-height: 30px; }
.lookgreatcnt p { font-size: 15px; line-height: 25px; }
.approachsec ul li strong { font-size: 41px; line-height: initial; } 
.approachsec ul li { align-items: flex-start; align-content: flex-start; vertical-align: top; } 
.approachsec ul {margin: 0;align-items: flex-start; align-content: flex-start; }
.corevalue { padding: 50px 0; }
.corevalue h2 { font-size: 30px; }
.corevalue h4 { font-size: 16px; line-height: 26px; }
.corevalue ul li p { font-size: 15px; line-height: 23px;}
.believeimg {width: 25%; padding: 0 10px;}
.webelive { padding: 40px 0; }
.clientsay h2 { font-size: 30px; }
/* Hire Developer */
.hiredevsec .col-md-12.col-lg-6 { z-index: 1; } 
.hiredevsec { height: auto; padding: 30px 0 0; min-height: inherit; } 
.looking{margin: 30px 0 30px;}
.bestworkimg img { max-width: 100%; }
.operatorimg { position: inherit; right: inherit; top: inherit; text-align: center; width: 100%; background: #faf9f7;}
.hiredevsec h2 { font-size: 30px; line-height: 40px; margin: 0 0 3px; }
.workbestsec { padding: 40px 0; }
.bestworkimg { text-align: center; }
.bestworkbox { margin: 30px 0 0; }
.blueheading { font-size: 24px; line-height: 28px; }
.getquote a,.getquote button { font-size: 15px;}
.pricesec { margin: 0 0 25px;min-height: 340px; }
.pricesec h2 br { display: none;}
.pricesec h2 { font-size: 20px;}
.pricecontent h3 { font-size: 30px; line-height: 40px; }
.pricecontent ul { margin: 10px 0 20px;}
.selecttopsec { padding: 40px 0; }
.passrate { width: 32%; }
.selectiondata {width: 62%;}
.hiretime { padding: 40px 0;}
.hiretimeheading { font-size: 28px; line-height: 35px;}
.hiretitle { margin: 0 0 20px;font-size: 16px; line-height: 23px;}
.hireprocess { height: auto; margin: 0 0 20px;}
.hireprocesscnt p { font-size: 15px;}
.benefitofhiring ul li p { font-size: 15px;}
.benefitofhiring { padding: 40px 0 10px; }
.postyourcv { padding: 50px 0; }
.postyourcv h2 { font-size: 25px; line-height: 35px; }
.postyourcv p { font-size: 16px; line-height: 25px;}
.faqsec { padding: 40px 0 10px; }
.faqlist h2.accordion-header button { font-size: 18px; line-height: 20px;}
/* blog */
.blogsec { padding: 40px 0; }
.innerbanner { padding: 30px 10px; }
.innerbanner h2 { font-size: 30px; line-height: 40px;}
.innerbanner h3 {font-size: 17px; line-height: 20px;}
.blogbig .blogdata h3 { font-size: 20px; line-height: 22px;}
.writtenby a { font-size: 13px;}
.blogdata h3 { font-size: 14px; line-height: 19px;}
/* blog detail */
.blogdetail { padding: 40px 0; }
.postdescription h2 { margin: 0 0 8px; font-size: 25px; line-height: 30px;}
.postdescription h3 { font-size: 20px; line-height: 21px;}
.similarblog { padding: 0 0 20px; }
.blogpostdetail { padding: 0 0 0 80px; }
.blogdate strong { font-size: 30px; line-height: 37px;}
.similarblog { padding: 0 0 20px; } 
/* portfolio */
.portfoliopage { padding: 40px 0 10px; }
.portdesc h3 { font-size: 20px; line-height: 22px;}
.portdesc p { font-size: 15px; line-height: 22px;}
/* portfolio detail */
.portdetailbanner img { max-width: 600px; }

.wave {
    display: none;
}
.analysis_sec{padding: 40px 0;}
.analysis_table { overflow-x: auto; } 
.analysis_table table.table { width: 1050px;margin-bottom: 5px; }
.analysis_table table.table tr th, .analysis_table table.table tr td{font-size: 15px;padding: 10px 10px;}
.analysis_sec h2 + p{font-size: 16px;line-height: 23px;margin-bottom: 20px;}

.cv_form form > div:not(:last-child) { margin-bottom: 0; } 
.cv_form form > div:not(:last-child) > div { margin-bottom: 15px; }

header .bluebtn,.button { padding: 9px 15px; line-height: 24px; font-size: 15px; } 
header .bluebtn { padding: 8px 15px; margin-right: 7px; } 
button.navbar-toggler { padding: 5px 10px; }


}

@media(max-width:767px){
.corevalue { padding: 40px 0 6px; }
.headertop .container { flex-wrap: wrap; flex-direction: column-reverse; justify-content: flex-start; align-items: flex-start; }   
.corevalue h4 { font-size: 14px; line-height: 23px; margin: 0 0 10px; } 
a.logo img { max-width: 130px; }
.headerRight { padding-right: 0; }
.headertop { padding: 10px 0;}
.headerRight { margin: 0 0 10px; width: 100%; display: flex; justify-content: space-between; align-items: center; align-content: center; }
.corevalue ul li:nth-child(4):after { display: block; }
button.navbar-toggler { right: 12px; bottom: 15px; top: inherit; padding: 3px 8px; border-radius: 2px; }
.navbar-collapse { top: 100%; right: 12px;}
.mainbanner { padding: 10px 0 20px; }
.bannercnt { text-align: center;}
.bannercnt h1 { margin: 0 0 5px; font-size: 25px; line-height: 28px; }
.bannercnt h1 br { display: none;}
.hiredev { margin: 12px 0 0;}
.trusted { padding: 20px 0 0; }
.updatetwitimg { position: relative; z-index: 1; display: flex; align-items: center; align-content: center; justify-content: center; flex-wrap: wrap; }
.hiredevimg {
  display: none;
}
.hiredevprocess {
  margin: 0;
}

.notsatis {
  margin: 0;
}
.yourcvload .container { position: relative; }
.jobfinderbg { right: 15px; }
.twitimgupdate1 {
  position: inherit;
  left: inherit;
  top: inherit;
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
  margin-bottom: 10px;
}

.industries h2.blueheading.mb-3 { margin-bottom: 2px!important; }
.twitimgupdate2 {
  position: inherit;
  left: inherit;
  top:inherit;
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
  margin-bottom: 10px;
}

.twitimgupdate3 {
  position: inherit;
  left: inherit;
  top: inherit;
  right: inherit;
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
  margin-bottom: 10px;
}

.twitimgupdate4 {
  position: inherit;
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
  top: inherit;
  left: inherit;
  right: inherit;
}

.twitimgupdate5 {
  position: inherit;
  left: inherit;
  top: inherit;
  right: inherit;
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
}

.wave__container {
  height: 200px;
}

.bannerimg { max-width: 380px; height: auto; margin: auto; position: relative; text-align: center; }
.bannerimg img { max-width: 300px; margin: auto; object-fit: contain; }
.footlink h2, .getintouch h2 { font-size: 19px; line-height: 18px; margin: 0 0 8px; }
.footlink ul li { font-size: 14px; line-height: 18px; }
.projectsteps .row .col-md-4:last-child .prostepbox { margin-bottom: 10px; }
.redtext { display: block; }
ul.servicelist li { width: 50%; }
.blueheading { font-size: 25px; line-height: 30px; }
.projectstartbluesec h2 { font-size: 25px; line-height: 30px; }
.projectstartbluesec p br { display: none;}
.prostepbox { margin: 0 0 40px; }
.hiredevelopersec { padding: 20px 0 20px; margin: 0; }
.notsatis h2 { font-size: 19px;}
.notsatis img { max-width: 80px; }
.hiredevdesc h2 { font-size: 18px; margin: 0 0 3px; line-height: 22px; }
.hiredevlist { margin: 0 0 12px; }
.induslogobox { padding: 8px; }
.induslogobox p { font-size: 14px;}
.recruitimg { display: none; }
/* .blueheading { font-size: 20px; line-height: 28px; } */
.whychoose ul li { font-size: 15px; margin: 0 0 7px; background-size: 22px; padding: 0 0 0 30px; }
.mainlap img { max-width: 270px; margin: auto; } 
.mainlap { text-align: center; }
.recruitfound { border-radius: 10px; }
.recruitcnt h2 { font-size: 18px; line-height: 22px; margin: 0 0 3px; }
.finddeveloper a { padding: 9px 23px; font-size: 13px; }
.yourcvload { height: auto;padding: 20px 0;}
.awardwininglogos ul li img { max-width: 80px; object-fit: contain; }
.devproceeicon { width: 17%; }
.projectstartbluesec { padding: 20px 10px 180px; }
.jobfinder { background: rgba(255,255,255,0.7); padding: 15px; }
.jobfinder h2 { font-size: 20px; line-height: 25px;}
.jobfinder p {padding-bottom: 40px;}
/* .industries { margin: 0 0 20px; } */
.footlink { border-top: 1px solid #3a3a3a; width: 100%; margin: 20px 0 20px; padding: 10px 0 0; border-bottom: 1px solid #3a3a3a; }
.copyright { margin: 10px 0 0; font-size: 13px; }
/* about */
.lookgreatcnt { margin: 0 0 20px; }
.lookgreatcnt h2 { font-size: 25px; line-height: 27px; margin: 0 0 10px; }
.lookgreatcnt p { margin: 0 0 10px; }
.lookgreatimg img { max-width: 290px; }
.approachsec ul li { width: 30%; }
.corevalue ul li { width: 49%; }
.corevalue ul li::before { display: none; } 
.believebox { flex-wrap: wrap; } 
.beliveboxcnt { width: 100%; } 
.belivelisting { margin: 0 0 10px; } 
.belivelisting h3 span { right: inherit; left: 0; }
.believeimg { width: 100%; padding: 0 10px; margin: 40px 0; }
.belivelisting { margin: 0 0 10px; }
.believetitle {margin: 0 0 10px; font-size: 15px; line-height: 22px; }
.clientsay { padding: 40px 0; }
.userdata p { font-size: 14px; line-height: 25px;}
.clientsay h2 { font-size: 24px; }
.belivelisting h3 { font-size: 24px; line-height: 29px;}
.clientsayslider button.slick-prev, 
.clientsayslider button.slick-next { display: none!important;}
.userdata p { padding: 0;}
.lookgreatimg { text-align: center; }
.corevalue ul h3 { font-size: 19px; line-height: 21px;}
/* Hiredeveloper */
.hiredevsec h2 { font-size: 22px; line-height: 32px; margin: 0 0 3px; }
.getquote a,.getquote button { font-size: 14px; padding: 9px 10px; }
.findprice { padding: 30px 0; }
.findprice > h2 { margin: 0 0 20px; }
.selecttopsec { padding: 20px 0; }
.topselectionlist { margin: 20px 0 0; }
.topselectionlist ul li { flex-wrap: wrap; }
.passrate { width: 100%; }
.selectiondata { width: 100%; padding: 0 0 10px; }
.topselectionlist ul li { flex-wrap: wrap; padding: 0 0 0 30px; position: relative;border-left: 2px solid #7B3FE4;} 
.passrate:after { right: inherit; left: -44px; width: 25px; height: 25px; top: 12px; } 
.passrate:before { right: inherit; left: -35px; width: 8px; height: 8px; top: 20px; }
.topselectionlist ul { padding: 0 0 0 10px; }
.passrate { border: none; }
.hiretimeheading { font-size: 22px; line-height: 28px; }
.hireprocesscnt h3 { font-size: 16px; }
.benefitofhiring ul li { width: 48%; }
button.hirenow { padding: 11px 20px; font-size: 13px; } 
.hireemail input { padding: 10px 110px 10px 10px; font-size: 14px; }
.looking {margin: 15px 0 10px;font-size: 14px;}
.hiredevsec p { margin: 0 0 10px;}
.projectstartsec { overflow: hidden; padding: 0 0 20px; }
/* blog */
.blogsec { padding: 20px 0 10px; }
.blogbig .blogdata h3 { margin: 0 0 5px; font-size: 16px; line-height: 18px; }
.blogdata p { font-size: 15px; line-height: 22px; }
.blogdata h3 { font-size: 14px; line-height: 18px; }
.writtenby a { font-size: 14px; line-height: 16px;}
.writtenby { margin: 0 0 3px; }
.innerbanner { padding: 30px 10px;}
.innerbanner h2 { font-size: 22px; line-height: 27px;}
.innerbanner h3 { font-size: 15px; line-height: 22px;}
.greentitle { font-size: 13px;}
.titleread { margin: 0 0 4px; }
.blogbig .blogdata { padding: 10px 10px 10px 10px; }
.bloglisting { margin: 0 0 15px;}
.paginations ul li { margin: 0 5px 5px;}
.paginations ul li a { padding: 5px 12px; font-size: 13px; }
.blogbig .titleread { margin: 0 0 3px; }
/* blog detail */
.blogpostdetail { padding: 0; } 
.blogdate { position: inherit; margin: 0 0 30px; text-align: center; }
.postdescription h2 { margin: 0 0 4px; font-size: 20px; line-height: 26px; }
.postdescription p { font-size: 15px; line-height: 22px;}
.blogdetail { padding: 20px 0 10px; }
.similarblog h2 {margin: 0 0 15px;font-size: 22px; line-height: 30px;}
.postdescription h3 { font-size: 17px; line-height: 21px; }
/* portfolio */
.portfoliopage { padding: 30px 0 10px; }
.portfoliopage h2 {margin: 0 0 20px; padding: 0 15px; font-size: 30px; line-height: 35px;}
/* .portdesc h3 { font-size: 20px; line-height: 22px;}
.portdesc p { font-size: 15px; line-height: 22px;} */
/* portfoliodetail */
.portdetailbanner img {
  max-width: 600px;
  border-radius: 7px;
}
.portdetailbanner { margin-left: 0; margin-right: 0; max-width: 100%; }

.portfoliodetailbanner h2 {
  padding-top: 30px;
  font-size: 22px;
  line-height: 30px;
}

.portfoliodetailbanner {
  height: 230px;
}
.overviewproject {
  padding:20px 0;
  
}

.overviewproject h2 {
  font-size: 23px; line-height: 31px; text-align: left; margin: 0 0 5px;
}

.overviewproject p {
  font-size: 16px;
  line-height: 24px;
}
.protechnology { width: 100%; margin: 0 0 10px; }
.projecdeslist { margin: 20px 0 0; }
.protechnology h3 { font-size: 20px; line-height: 23px;}
.protechnology ul li { font-size: 16px; line-height: 25px; padding: 0 0 0 21px;}
.protechnology ul li:before { width: 15px; height: 13px; background-size: 100%; top: 7px; }
.visitsitebnt { margin: 10px 0 0; }
.visitsitebnt a { padding: 12px 20px; font-size: 13px; }

/* 10 Jan 23 B */
.bannercnt h1 .redtext { display: inline; }
.alldeveloperlisting { padding: 0 0 15px; } 
.developerdetail { margin-bottom: 20px;padding: 15px; }
.searchhead p br{display: none;}
.searchhead p,.candidatematch h3{font-size: 16px;line-height: 24px;}
.searchhead h2,.candidatematch h2{font-size: 22px;line-height: 30px;}
.searchform .devform{padding: 15px;}
.upgrageplanbtn_box{margin-top: 20px;}
.candidatematch { padding: 30px 10px; }
.emailpop .modal-title { font-size: 18px; } 
.emailpop .modal-body { padding: 20px 15px; } 
.emailpop .modal-header { padding-left: 15px; padding-top: 20px; padding-right: 30px; }
.searchform .devform ul li select { padding: 10px 10px; } button.searchbutton { font-size: 16px; line-height: 23px; padding: 10px 15px; }
.analysis_sec{padding: 30px 0;}

header .bluebtn, .button { line-height: 20px; }
.captcha { width: 100%; margin-bottom: 15px; } 
form .button-mat { margin-left: 0 !important; }
.hireform .modal-body { padding: 15px; } 
.devdetails { margin-bottom: 25px; }

}


@media(max-width:576px){
.trusted ul li { margin: 0 7px 0 0; }
ul.servicelist li {width: 100%; }
.ourservsec { padding: 30px 0 10px; }
/* .induslogobox { width: 90%; margin: 0;} */
.industrieslogo .slick-list {padding: 20px 14px; text-align: center;}
/* .twitimgupdate3 { right: 80px; } */
.twitimgupdate4 { width: 101px;}
.twitimgupdate5 { width: 100px; }
.projectstartbluesec h2 { font-size: 18px; line-height: 24px; }

.industries { padding: 20px 0 4px; }
.hiredevdesc {width: 90%; padding-left: 10px;}
.hiredevdesc p { font-size: 14px; line-height: 21px;}
.recruitfound { border-radius: 10px; flex-wrap: wrap; }
.recruitimg { margin: 0 0 20px;order: 2; width: 46%; }
/* .recruitcnt { margin: 0 0 30px; } */
.recruitsec { padding: 10px 0; }
.whychoose { padding: 20px 0 0; }
.jobfinder p { padding-bottom: 0; }
.awardwining { padding: 20px 0; }
.awardtitle { font-size: 15px; line-height: 20px; }
.awardwininglogos ul li { width: 48%; }
/* about */
.lookgreadtsec { padding: 20px 0; }
.approachsec ul li { width: 31%; }
.approachsec ul li strong { font-size: 25px;}
.approachsec ul li span { font-size: 12px; line-height: 15px; }
.approachsec { padding: 30px 0 10px; }
.corevalue h2 { font-size: 24px; line-height: 28px; }
.corevalue ul li { width: 100%;height: auto; }
.blueheading { font-size: 21px;}

.twitimgupdate1 { width: 80px; }
.twitimgupdate2 { width: 80px;  }
.devdescription p { font-size: 12px; line-height: 15px; }
.twitimgupdate3 { width: 80px;}
/* portfolio */
.portfoliopage { padding: 20px 0 10px; }
.portfoliopage h2 {margin: 0 0 10px; font-size: 25px; line-height: 30px;}

/* 12-Jan */
.benefitofhiring ul li { width: 100%; margin: 0 0 20px; } 
.benefitofhiring ul li:last-child { margin: 0; }
.readbtn,.upgrageplanbtn a, .upgrageplanbtn button { font-size: 16px; line-height: 22px; padding: 10px 15px; font-weight: 400; }
.postyourcv h2 { font-size: 20px; line-height: 26px; } 
.postyourcv h2 br { display: none; } 
.postyourcv { padding: 30px 0; }
.bestworklist ul li { width: 100%; }
.blueheading br{display: none;}

}

@media(max-width:480px){
.topbtns a.submitcvbtn { padding: 5px 6px; font-size: 11px; border-radius: 3px; } 
.topbtns .bluebtn, .topbtns .btn--7 { padding: 5px 10px; font-size: 11px; border-radius: 3px; }
.topbtns .btn--7 { padding: 6px 10px; }
.headerRight ul li a { width: 28px; height: 28px; } 
.headerRight ul li a img { max-width: 11px; } 
.headerRight ul li { margin: 0 3px 0 0; }
}
@media(max-width:400px){
  .twitimgupdate4 .devdescription { max-width: 100px; }
  .twitimgupdate5 .devdescription { max-width: 100px;}
  .bannerimg img { max-width: 100%;}
  ul.servicelist:before {display: none;}
  .getdoteleft, .getdoteright,.recdoteright { display: none; } 
  .getintouch ul li a { width: 29px; height: 29px; }
}

@media(max-width:360px){
a.submitcvbtn {padding: 5px 5px; font-size: 11px; border-radius: 3px; margin: 0 4px 0 0;}
header .button, .topbtns .bluebtn { padding: 5px 7px; font-size: 11px;line-height: 17px; border-radius: 3px; }
.devproceeicon { width: 20%; }
.topbtns .btn--7 { padding: 6px 7px !important; }
}







